import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { getLanguage } from "../../../../../../Helpers/LanguageHelper";
import { useMutation } from "react-query";
import axios from "axios";
import { StorageHelper } from "../../../../../../Helpers/StorageHelper";

interface IProps {
  open: boolean;
  freelancerId: string;
  handleClose: () => void;
}

export const BlockDialogue = (props: IProps) => {
  const postCollectiveAgreement = useMutation(() => {
    return axios.post(
      `/api/freelancer/${props.freelancerId}/block`,
      {},
      {
        headers: {
          "Content-type": "application/json",
          authToken: new StorageHelper().getAccessToken(),
        },
      }
    );
  });
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      className="dialog"
    >
      <DialogTitle id="form-dialog-title">
        Confirm that you want to block this chabber
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please confirm that you would like to block this chabber from applying
          for future shifts with your company.
        </DialogContentText>
      </DialogContent>
      <DialogActions className="dialogActions">
        <Button onClick={props.handleClose}>
          {getLanguage(242, "Cancel")}
        </Button>
        <Button
          onClick={async () => {
            await postCollectiveAgreement.mutateAsync();
            props.handleClose();
          }}
          color="primary"
        >
          {getLanguage(737, "Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
